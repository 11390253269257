import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'button',
    'dot',
    'input'
  ]

  toggle() {
    this.buttonTarget.classList.toggle('bg-gray-200')
    this.buttonTarget.classList.toggle('bg-amber-600')
    this.dotTarget.classList.toggle('translate-x-0')
    this.dotTarget.classList.toggle('translate-x-5')
    this.inputTarget.value = this.inputTarget.value === 'true' ? 'false' : 'true'
  }
}
