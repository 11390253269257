import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    siteKey: String
  }

  connect() {
    if (document.getElementById('cloudflare-turnstile')) {
      this.createCaptcha()
    } else {
      this.loadCaptcha()
    }
  }

  disconnect() {
    turnstile.remove(this.element)
    this.element.innerHTML = ''
  }

  loadCaptcha() {
    const script = document.createElement('script')
    script.id = 'cloudflare-turnstile'
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit'
    script.onload = () => this.createCaptcha()
    document.head.appendChild(script)
  }

  createCaptcha() {
    turnstile.render(this.element, {
      sitekey: this.siteKeyValue,
      theme: 'light',
    })
  }
}
