import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'mobileOffset',
    'mobileBackdrop'
  ]

  open() {
    this.mobileBackdropTarget.classList.remove('hidden')
    this.mobileOffsetTarget.classList.remove('hidden')
  }

  close() {
    this.mobileBackdropTarget.classList.add('hidden')
    this.mobileOffsetTarget.classList.add('hidden')
  }
}
